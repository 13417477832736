<template>
  <form @submit.prevent="submitModalityTest()">
    <!-- Traitement phytosanitaire -->
    <div class="fiche-pesees-phyto-edit-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Volumes non épandus</h2>
        </Container>
      </div>
      <Section>
        <Container>
          <div class="btn-bar btn-bar--right btn-bar--sub-h2">
            <Btn
              color="primary"
              hollow
              text="Paramètres de la fiche"
              @click="openModal('params', parameters)"
            />
            <Btn
              color="primary"
              hollow
              text="Imprimer la fiche"
              @click="download('pdf', 'evenement', $route.params.tid)"
            />
          </div>
          <form>
            <Table
              :headers="headers"
              :static-headers="staticHeaders"
              :items="formattedModalities"
              :hiddenItems="['id', `${mode_calcul === 'volume_applique' ? 'volume_restant' : 'volume_applique'}`]"
              tools
              :showModalitiesNumber="false"
              @table-row-validate="validate($event)"
            >
              <template v-slot:tools="{ item }">
                <Btn
                  @click="openModal('edit', item.item)"
                  icon="create"
                  color="white"
                  title="Modifier la modalité"
                />
              </template>

            </Table>

            <div class="margin-top">
              <h3>Validation</h3>
              <div class="grid">
                <Input
                  type="date"
                  id="date_validation"
                  label="Date"
                />
                <Input
                  id="validation_trigramme"
                  label="Trigramme"
                  :options="{
                    maxLength: 3
                  }"
                />
              </div>
            </div>
          </form>
        </Container>

      </Section>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'seeds'}" />
          <Btn
            btnType="submit"
            text="Enregistrer"
            color="primary"
          />
        </div>
      </div>

    </div>

    <!-- Modal pour une modalité -->
    <Modal
      type="global"
      title="Edition d'une modalité"
      :active="modal.edit"
      :data="modalData"
      @modal-close="modal.edit = false"
      :containForm="true"
      :modalValidationSchema="modaliteSchema"
      @modal-form-submitted="submitModality(modalData.id, $event)"
    >
      <template v-slot:modal-body>
        <Input
          v-if="mode_calcul === 'volume_restant'"
          label="Volume restant"
          type="number"
          id="volume_restant"
          :options="{ step: 0.1, min: 0, max: volumeTheoriqueBouillie }"
          :textInfo="`Le volume doit être inférieur à ${volumeTheoriqueBouillie}`"
        />
        <Input
          v-if="mode_calcul === 'volume_applique'"
          label="Volume appliqué"
          type="number"
          id="volume_applique"
          :options="{ step: 0.1, min: 0, max: volumeTheoriqueBouillie }"
          :textInfo="`Le volume doit être inférieur à ${volumeTheoriqueBouillie}`"
        />
      </template>

      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modal.edit = false" />
        <Btn text="Enregistrer" color="primary" type="submit" />
      </template>

    </Modal>

    <!-- Modal des paramètres de la fiche -->
    <Modal
      type="parameters"
      title="Paramétrage de la fiche de volumes non épandus"
      :active="modal.params"
      :data="modalData"
      @modal-close="modal.params = false"
      size="lg"
    >
      <template v-slot:modal-body>
        <Radio
          id="mode_calcul"
          v-model="mode_calcul"
          label="Mode de calcul"
          required
          :items="[
            { label: 'Volume appliqué', value: 'volume_applique' },
            { label: 'Volume restant', value: 'volume_restant' },
          ]"
          inputStyle="inline"
        />
        <KeyValue
          label="Surface traitée"
          :value="surfaceTraitee + ' m2'"
        />
        <Input
          id="volume_ha"
          v-model="volume_ha"
          label="Volume ha"
          type="number"
          input-after="L"
          required
          :options="{ step: 1, min: 0, max: 100 }"
          :value="100"
        />
        <KeyValue
          label="Volume théorique bouillie"
          :value="volumeTheoriqueBouillie + 'L'"
        />
        <Input
          id="ecart_tolere"
          v-model="ecart_tolere"
          label="Écart toléré"
          type="number"
          input-after="%"
          required
          :options="{ step: 0.1, min: 0, max: 100 }"
        />
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modal.params = false" />
        <Btn text="Enregistrer la validation" color="primary" type="submit" @click="submitParameters()" />
      </template>

    </Modal>

    <Loader :active="loading" />
  </form>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Modal from '@/components/layout/Modal.vue'
import Container from '@/components/layout/Container.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'VolumesNonEpandusSubview',
  components: {
    KeyValue,
    Btn,
    Table,
    Container,
    Input,
    Modal,
    Radio,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    const parametersSchema = this.yup.object().shape({
      calcul_volume_applique: this.yup.number().nullable(),
      volume_ha: this.yup.number().nullable(),
      ecart_tolere: this.yup.number().nullable(),
    })

    const modaliteSchema = this.yup.object().shape({
      volume_restant: this.yup.number().nullable(),
      volume_applique: this.yup.number().nullable(),
    })

    const validationSchema = this.yup.object().shape({
      date_validation: this.yup.date().nullable(),
      validation_trigramme: this.yup.string()
        .min(3, 'Le trigramme doit contenir 3 caractères')
        .max(3, 'Le trigramme doit contenir 3 caractères')
        .nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const submitModalityTest = this.formService.handleSubmit((values) => {
      this.submitValidation(values)
    })

    return {
      submitModalityTest,
      isSubmitting,
      errors,
      parametersSchema,
      modaliteSchema,
      modal: {
        params: false,
        edit: false,
      },
      modalData: null,
      loading: true,
      headers: [],
      staticHeaders: [
        'Volume restant',
        'Écart réel (%)',
        'Conformité',
      ],
      modalities: {},
      formattedModalities: [],
      fiche: null,
      fiche_volume_non_epandus_id: null,
      evenement_nom: null,
      mode_calcul: 'volume_applique',
      volume_ha: 100,
      volume_applique: 0,
      volume_restant: 0,
      ecart_tolere: 10,
      unites: [],
      unites_dose_epandue: [],
      parameters: null,
      componentKey: 0,
    }
  },
  computed: {
    surfaceTraitee() {
      return this.task.essai.surface_application_longueur * this.task.essai.surface_application_largeur * this.task.essai.plan.nb_repetitions
    },
    volumeTheoriqueBouillie() {
      return (this.surfaceTraitee * this.volume_ha) / 10000
    },
  },
  created() {
    this.loading = true
    this.setComponent()
    this.loading = false
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      if (modal === 'edit') {
        this.modalData = this.getModality(data)
      }
      this.modal[modal] = true
    },

    setComponent() {
      this.emitter.emit('open-loader')

      this.taskService.setRoute(this.$route)
      this.taskService.setFormService(this.formService)
      this.taskService.getVolumesNonEpandusFiche(true).then((fp) => {
        this.mode_calcul = fp.calcul_volume_applique ? 'volume_applique' : 'volume_restant'
        this.staticHeaders[0] = fp.calcul_volume_applique ? 'Volume appliqué' : 'Volume restant'
        this.ecart_tolere = fp.ecart_tolere ? fp.ecart_tolere : this.parseParameterValue('PHYTO_VOLUMENONEPANDUS_ECARTOLERE')
        this.volume_ha = fp.volume_ha ? fp.volume_ha : this.parseParameterValue('PHYTO_VOLUMENONEPANDUS_VOLUMEHA')
        this.fiche_volume_non_epandus_id = fp.id
        this.fiche = fp
        this.taskService.getVolumesNonEpandusModalities().then((rm) => {
          this.modalities = rm
          this.taskService.getVolumesNonEpandusFormattedModalities(
            this.modalities,
            this.volumeTheoriqueBouillie,
            this.ecart_tolere,
            this.mode_calcul,
          ).then((rfm) => {
            this.formattedModalities = rfm
            this.loading = false
            this.emitter.emit('close-loader')
          })
        })
      })
    },

    parseParameterValue(parameterName) {
      try {
        const parameterValue = JSON.parse(this.$store.getters['app/parameters']([parameterName])[0].valeur)

        // eslint-disable-next-line
        return typeof parameterValue === 'string' && parameterValue ? parameterValue : typeof parameterValue === 'object' && parameterValue ? parameterValue : null
      } catch (e) {
        console.log(e)
        return null
      }
    },

    getModality(modality) {
      return {
        id: modality.id,
        volume_restant: modality.volume_restant,
        volume_applique: modality.volume_applique,
      }
    },

    download(type, ressource, rid) {
      this.taskService.downloadVolumeNonEpandusFiche(type, ressource, rid)
    },

    /**
     * Submit modal parameters
     * @returns {Promise<void>}
     */
    async submitParameters() {
      const params = {}
      params.ecart_tolere = this.ecart_tolere
      params.calcul_volume_applique = this.mode_calcul === 'volume_applique'
      params.volume_ha = this.volume_ha

      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/volume_non_epandus/${this.fiche_volume_non_epandus_id}`
      const response = await this.fetchService.put(endpoint, params)
      const metas = await response.meta
      if (metas.success) {
        this.setComponent()
        this.modal.params = false
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Les paramètres de la fiche de volumes non épandus ont bien été modifiés.',
        })
      }
    },

    /**
     * Call submit modality with id
     * @param event
     */
    validate(event) {
      this.submitModality(event.id, event)
    },

    /**
     * Submit modality
     * @param id
     * @param values
     * @returns {Promise<void>}
     */
    async submitModality(id, values) {
      const params = { ...values }
      if (this.mode_calcul === 'volume_applique') {
        delete params.volume_restant
      } else {
        delete params.volume_applique
      }

      const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/volume_non_epandus/modalite/${id}`
      const response = await this.fetchService.put(endpoint, params)
      if (response.meta.success) {
        this.mods = []
        this.loading = true
        this.setComponent()
        this.modal.edit = false
        const designation = response.data.modalite
          ? response.data.modalite.designation
          : 'Essai à blanc'
        this.emitter.emit('alert', {
          type: 'success',
          content: `La modalité "${designation}" a bien été modifiée.`,
        })
      }
    },

    /**
     * Submit validation
     * @param values
     */
    submitValidation(values) {
      const params = { ...values }
      params.date_validation = this.helperService.formatDateForApi(values.date_validation)
      const endpoint = `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/volume_non_epandus/${this.fiche_volume_non_epandus_id}`
      this.fetchService.put(endpoint, params).then(() => {
        this.setComponent()
        this.modal.params = false
        this.emitter.emit('alert', {
          type: 'success',
          content: 'Les paramètres de la fiche de volume non épandus ont bien été modifiés.',
        })
      })
    },
  },
}
</script>
